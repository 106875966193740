<template>
  <div class="page-wrapper" v-loading="loading">
    <login @onLogin="login" />
  </div>
</template>

<script>
// これ同じ名前可能なのかな
import Login from '../../components/Login';
import { mapActions, mapState } from 'vuex';
import { ActionTypes } from '../../store/actions';

export default {
  name: 'LoginPage',
  components: {
    Login,
  },
  data() {
    return {
      loadingView: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await vm.$store.dispatch(ActionTypes.initApp);
      if (vm.$store.state.isLogin) {
        return next('/');
      } else {
        return next();
      }
    });
  },
  computed: {
    loading() {
      return this.$store.state.loading['sign-in'];
    },
  },
  methods: {
    ...mapActions([ActionTypes.signIn]),
    async login({ id: mail, password }) {
      try {
        await this.signIn({
          mail,
          password,
        });
        this.goNextPage();
      } catch (e) {
        console.error(e);
      }
    },
    goNextPage() {
      const { redirect, ...query } = this.$route.query;
      this.$router
        .push({
          path: redirect || '/',
          query,
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; //ここを100%にすると高さが親に合わない
}
</style>
