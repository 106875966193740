<template>
  <div class="wrapper">
    <div class="title">cc wallet console</div>
    <el-form>
      <el-form-item>
        <el-input
          placeholder="ID"
          prefix-icon="el-icon-user-solid"
          v-model="form.id"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          placeholder="Password"
          prefix-icon="el-icon-lock"
          v-model="form.password"
          type="password"
        />
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="$emit('onLogin', form)">LOGIN</el-button>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      form: {
        id: '',
        password: '',
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  width: 25vw;
  padding: 3rem 2rem;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  border-radius: 8px;

  & > * {
    margin-bottom: 8px;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 749px) {
    width: 80vw;
  }
}

.title {
  text-align: center;
  font-size: 2rem;
}
</style>
